import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { firestore } from "../../services/firebase/index";
import THEME from "../../config/theme";
import { ActivityIndicator } from "react-native-paper";
import { Container } from "./style";
import { useNavigation } from "@react-navigation/native";
import Table from "./DataTable";
import {
    getDocs,
    collection,
    query,
    doc,
    where,
    setDoc,
    Timestamp,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getCountUsers, getUserById, getUserContent, getUsers } from "./user-service";
import { getProducts } from "./products-service";
import { getVideos } from "./category-service";

const functions = getFunctions();
const auth = getAuth();

export function Users() {
    const PAGE_SIZE = 10;

    const navigation = useNavigation();
    const [usersPage, setUserPage] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const [user, setUser] = useState(true);
    const [filter, setFilter] = useState();
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [userReference, setUserReference] = useState([]);
    const [countUsers, setCountUsers] = useState();
    const [countFiltered, setCountFiltered] = useState();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

    const getUserAdmin = async (adminId) => {
        const docSnap = await getUserById(adminId);
        if (docSnap.exists()) {
            setUser(docSnap.data());
        }
    };

    const fetchContentsForUsers = async (users) => {
        const promises = users.map(async (user) => {
            const contentsSnapshot = await getUserContent(user.id);

            const watchedContents = contentsSnapshot.docs
                .filter((contentDoc) => contentDoc.data().isWatched)
                .map((contentDoc) => ({
                    categoryId: contentDoc.data().categoryId,
                    cardId: contentDoc.data().cardId,
                    videoId: contentDoc.id,
                    markedAsWatchedAt: contentDoc.data().markedAsWatchedAt
                }))
                .filter(content => content.categoryId && content.cardId && content.videoId); // Filtrando conteúdos com IDs válidos

            return { ...user, watchedContents };
        });

        return Promise.all(promises);
    };

    const fetchVideoTitles = async (usersWithContents) => {
        const usersWithVideoTitles = await Promise.all(
            usersWithContents.map(async (user) => {
                const { watchedContents } = user;
                const userWithVideoTitles = { ...user, watchedVideos: [] };
    
                for (const content of watchedContents) {
                    // Verifica se todos os IDs necessários estão presentes
                    if (content.categoryId && content.cardId && content.videoId) {
                        const videosSnapshot = await getVideos({categoryId: content.categoryId, cardId: content.cardId});
    
                        videosSnapshot.forEach((videoDoc) => {
                            const videoData = videoDoc.data();
                            if (videoDoc.id === content.videoId) {
                                userWithVideoTitles.watchedVideos.push(videoData.title);
                            }
                        });
                    } else {
                        console.warn(`Missing IDs for content:`, content);
                    }
                }
    
                return userWithVideoTitles;
            })
        );
    
        return usersWithVideoTitles;
    };   

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
            if (authUser) {
                getUserAdmin(authUser.uid);
                fetchUsersPage(null, "", true);

                //count All Users
                getCountUsers({})
                .then(res => {
                    const allUsersCount = res.data().count;
                    setCountUsers(allUsersCount);
                    setCountFiltered(allUsersCount);
                })

                //fetch products
                getProducts()
                .then(response => {
                    setProducts(response.docs.map(doc => ({
                        ...doc.data(),
                        selected: false,
                    })));
                });
            } else {
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    const fetchAllUsers = async () => {
        const response = await getUsers({})

        const users = response.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        const usersWithContents = await fetchContentsForUsers(users);
        return await fetchVideoTitles(usersWithContents);
    }

    const fetchUsersPage = async (currentFilter, emailReference, isNextPage) => {
        setTableLoading(true);
        try {
            const response = await getUsers({
                pageReference: emailReference,
                pageSize: PAGE_SIZE,
                isNextPage: isNextPage,
                email: currentFilter,
            })

            const users = response.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            const usersWithContents = await fetchContentsForUsers(users);
            const useithVidrsWeoTitles = await fetchVideoTitles(usersWithContents);
            
            setUserPage(useithVidrsWeoTitles);
            setUserReference(response.docs);
        } catch (error) {
            console.error("Error fetching users:", error);
        } finally {
            setTableLoading(false);
            setLoading(false);
        }
    }

    const handlePageChange = (page) => {
        if (page > currentPage) {
            fetchUsersPage(filter, userReference[userReference.length-1], true);
        } else {
            fetchUsersPage(filter, userReference[0], false);
        }

        setCurrentPage(page);
    };

    const handleSaveUser = async ({ email, name, selectedProducts, endDate }) => {
        try {
            const usersCollection = collection(firestore, 'users');
            const q = query(usersCollection, where("Email", "==", email));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const userId = querySnapshot.docs[0].id;
                const existingUserDoc = querySnapshot.docs[0];
                const existingProductIds = existingUserDoc.data().productIds || [];

                const newSelectedProductIds = selectedProducts
                    .filter(product => product.selected)
                    .map(product => ({
                        productId: product.productId,
                        expirationDate: Timestamp.fromDate(new Date(endDate))
                    }));

                const combinedProductIds = [
                    ...existingProductIds,
                    ...newSelectedProductIds.filter(
                        newProduct => !existingProductIds.some(
                            existingProduct => existingProduct.productId === newProduct.productId
                        )
                    )
                ];

                await setDoc(doc(firestore, 'users', userId), { productIds: combinedProductIds }, { merge: true });
            } else {
                const createUserFunction = httpsCallable(functions, "createUser");
                await createUserFunction({ email, name, selectedProducts, endDate });
            }
        } catch (error) {
            console.error('Erro ao salvar usuário:', error);
        }
    };



    const handleFilter = async (text) => {
        setFilter(text)
        fetchUsersPage(text, null, true)
        
        getCountUsers({email: text})
        .then(res => {
            setCountFiltered(res.data().count);
        })
       
        setResetPaginationToggle(!resetPaginationToggle)
    }


    return loading ? (
        <ActivityIndicator
            style={{
                flex: 1,
                backgroundColor: "#FFFFFF",
                justifyContent: "center",
            }}
            color={THEME.COLORS.PRIMARY_900}
            size="large"
        />
    ) : (
        <Container>
                        <Header navigation={navigation} user={user} isAdminPage/>
                        <Table
                tableData={usersPage}
                user={user} 
                products={products} 
                totalCount={countUsers}
                totalFiltered={countFiltered}
                loading={tableLoading}
                handleSaveUser={handleSaveUser}
                handlePageChange={handlePageChange}
                handleFilter={handleFilter}
                fetchAllUsersHandler={fetchAllUsers}
                resetPaginationToggle={resetPaginationToggle}
            />
        </Container>
    );
}